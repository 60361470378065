// @flow

import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { HEYBUDDY_URL, IUP_URL } from "../helper/variables";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  logo: {
    // width: "450px",
    // height: "300px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    opacity: 1,
    "@media (max-width: 1600px)": {
      width: "70vh", // Change direction to row
    },
    "@media (max-width: 1250px)": {
      width: "55vh", // Change direction to row
    },
  },
  signinButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",

    "@media (max-width: 1600px)": {
      width: "80%", // Change direction to row
    },
  },
  forgotButton: {
    margin: "auto",
  },

  image: {
    backgroundImage: "url(/assets/images/landing_bg.svg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
  },

  mask: {
    backgroundColor: "black",
    opacity: 0.5,
    position: "absolute", // Add absolute positioning
    backgroundSize: "100% 100%",
    height: "100vh",
    width: "-webkit-fill-available",
    zIndex: 1,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  imageItem: {
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundSize: "100% 100%",
    width: "19vh",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    "@media (max-width: 1600px)": {
      width: "15vh",
    },
    "@media (max-width: 960px)": {
      width: "18vh",
    },
    "@media (max-height: 740px)" : {
      width: "19vh"
    },
    "@media (max-width: 455px)" : {
      display: "none"
    }
  },
  logoDiv: {
    marginTop: "30vh",
    zIndex: 2,
    height: "10vh",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 1600px)": {
      alignItems: "center",
    },
    
  },
  logos: {
    zIndex: 2,
    height: "10vh",
    display: "flex",
    marginTop: "5vh",
    "@media (max-width: 1600px)": {
      width: "40vh",
      justifyContent: "space-between"
    },
  },
  operatorDiv: {
    padding: "15px",
    paddingRight: "29px",
    display: "flex",
    height: "19vh",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width: 1600px)": {
      height: "15vh",
    },
    "@media (max-width: 1060px)": {
      paddingLeft: "4vh"
    },
    "@media (max-width: 500px)": {
      height: "18vh",
    },
    "@media (max-width: 455px)": {
      paddingRight: "15px",
    },
    "@media (max-width: 290px)": {
      justifyContent: "center",
    },
    "@media (max-height: 740px)": {
      height: "19vh",
    },
    "@media (max-width: 524px)": {
      justifyContent: "center",
    },
    "@media (max-height: 524px)": {
      justifyContent: "center",
    },
  },
  cardDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 960px)": {
      backgroundImage: "url(/assets/images/landing_bg.svg)",
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  },
  
}));

function Home() {
  const [loading ] = useState(false);

  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Hidden smDown>
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          lg={7}
          className={classes.image}
        >
          <div className={classes.mask}></div>
          <div className={classes.logoDiv}>
            <img
              alt="logo"
              className={classes.logo}
              src="/assets/images/main-logo.svg"
            />
            {/* <div >
                <span className="logo-title"><b className="custom-bold">DASL</b> IUP</span>
                <span className="logo-content">DASL IUP</span>
            </div> */}
            <div className={classes.logos}>
              <img
                alt="logo"
                className={classes.logo}
                id="logo1"
                src="/assets/images/logo1.svg"
              />
              <img
                alt="logo"
                id="logo2"
                className={classes.logo}
                src="/assets/images/logo2.svg"
              />
            </div>
          </div>
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={5}
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        className={classes.cardDiv}
      >
        <div className={classes.paper}>
          <Box className="signIn-div">
            <Grid container>
              <Grid item xs={4}>
                <img
                  alt=""
                  src="/assets/images/iup-image.svg"
                  className={classes.imageItem}
                />
              </Grid>
              <Grid item xs={8} className={classes.operatorDiv}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="card-title">
                    <b>DASL</b> IUP
                  </span>
                  <span className="card-content">
                    Incident management module
                  </span>
                </div>
                <Button
                  className={classes.signinButton}
                  variant="contained"
                  size="large"
                  fullWidth
                  href={IUP_URL}
                  disabled={loading}
                >
                  Login to IUP
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <p></p>
          <Box className="signIn-div">
            <Grid container>
              <Grid item xs={4}>
                <img
                  alt=""
                  style={{ padding: "0px" }}
                  src="/assets/images/heybuddy-image.svg"
                  className={classes.imageItem}
                />
              </Grid>
              <Grid item xs={8} className={classes.operatorDiv}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="card-title">
                    <b>HeyBuddy</b>
                  </span>
                  <span className="card-content">
                    Tenants management module
                  </span>
                </div>
                <Button
                  className={classes.signinButton}
                  variant="contained"
                  size="large"
                  fullWidth
                  href={HEYBUDDY_URL}
                  disabled={loading}
                >
                  Login to HeyBuddy
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default Home;
